<template>
  <div class="pro home">
<!--
    <bloc-connexion v-if="!addeoSmall" :header="true" />
-->
    <bloc-header/>
    <bloc-quois/>
    <bloc-pour-qui/>
    <bloc-porteurs/>
<!--
    <bloc-reseau/>
    <bloc-grand-est/>
    <bloc-inscription/>
-->
    <bloc-carousel/>
    <bloc-footer/>
  </div>
</template>


<script>
//import Connexion from '@/components/Connexion.vue'
import Header from '@/components/Header.vue'
import Quois from '@/components/pro/Quois.vue'
//import Reseau from '@/components/Reseau.vue'
import Porteurs from '@/components/pro/Porteurs.vue'
import PourQui from '@/components/pro/PourQui.vue'
//import GrandEst from '@/components/pro/GrandEst.vue'
//import Inscription from '@/components/pro/Inscription.vue'
import Carousel from '@/components/Carousel.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Portail-Lorfolio-Pro',
        data() {
            return {
                tmp:true,
            }
        },
        components: {
//'bloc-connexion': Connexion,
            'bloc-header': Header,
            'bloc-quois': Quois,
//            'bloc-reseau': Reseau,
            'bloc-porteurs': Porteurs,
            'bloc-pour-qui': PourQui,
//            'bloc-grand-est': GrandEst,
//            'bloc-inscription': Inscription,
            'bloc-carousel': Carousel,
            'bloc-footer': Footer,
        },
        computed:{
                addeoSmall() {
                    return(this.$parent.addeoSmall);
                },
                addeoMedium() {
                    return(this.$parent.addeoMedium);
                },
                addeoLarge() {
                    return(this.$parent.addeoLarge);
                },
        },
        beforeCreate() {
                this.$parent.sitePRO = true;
        }


}
</script>
