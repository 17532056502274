<template>
    <div id="unOutil">

                <img class="illustration" :src="getImgUrl" :alt="data.itemsTxt[item]" />
                <span class="legende" v-if="!data.items[item]">
                    <span v-html="data.itemsTxt[item]"></span>
                    <div class="fond cliquable"><i class="fa fa-hand-pointer-o"></i></div>
                    <div class="fond cliquable droite"><i class="fa fa-hand-pointer-o"></i></div>
                </span>
  <transition name="slide-fade">
                <span class="legende titre" v-if="data.items[item]">
                    <span v-html="data.itemsTxt[item]"></span>
                    <div class="fond cliquable"><i class="fa fa-hand-pointer-o"></i></div>
                    <div class="fond cliquable droite"><i class="fa fa-hand-pointer-o"></i></div>
                </span>
  </transition>
<!--
  <transition name="slide-fade">
                <div class="contenu" v-if="data.items[item]">
                    <div v-html="data.itemsCont[item]"></div>
                </div>
  </transition>
-->
                <img v-if="" class="fond" src="../../../assets/img/pro/quois/cadreNormal.png" alt="" />
                <img  v-if="" class="fond survol" src="../../../assets/img/pro/quois/cadreSurvol.png" alt="" />
    </div>
</template>

<script>
export default {
    name: 'Outil',
        props: {
            data: {
                type: Object
            },
            item: {
                type: String
            }
        },
    data() {
        return {
            tmp:true
        }
    },
    computed: {
        getImgUrl() {
            if (this.$parent.addeoSmall) {
                var image = "/"+this.data.imagesS[this.item];
            }
            else {
                var image = "/"+this.data.images[this.item];
            }
            //var toto = require(image);
            //return imageName ? toto : ''
            return  image
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #quoi {

    }
</style>
