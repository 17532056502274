<template>
    <div id="pourQui">
        <div class="containerFond">
<!--
            <div class="gauche"></div>
-->
            <div class="containerAffichage">
                <h2 class="sousTitre" v-if="markOrientest">
                    <span class="commeSousTitre">Pour les personnels de l’éducation en charge de l’accompagnement à l’orientation</span>
                </h2>

                <p class="teaser" v-if="0">
                    Texte de présentation ?
                </p>

                <div class="containerConsigne">
                    <div class="consigne" v-bind:class="{avecBloc : blocConnexion}">
                        <a v-if="!blocConnexion" class="bouton lien"  @click.stop="blocConnexion=!blocConnexion">
                            <span>Connexion / Inscription</span>
                            <i class="fa fa-solid fa-caret-down"></i>
                        </a>
                        <i v-if="blocConnexion" class="fa fa-solid fa-caret-up lien"  @click.stop="blocConnexion=!blocConnexion"></i>
                    </div>
                </div>
                <div class="clear" />
<transition name="fadeHeight">
                <div class="conteneurConnexion" v-if="blocConnexion">
                    <!-- <h3>M'identifier</h3> -->
                    <bloc-connexion />
                </div>
</transition>
                <div class="clear" />
            </div>
        </div>
    </div>
</template>

<script>

import Connexion from '@/components/Connexion.vue'

export default {
    name: 'PourQui',
    components: {
        'bloc-connexion': Connexion,
        },
    props: {
    },
    data() {
        return {
            blocConnexion:false
        }
    },

    computed: {
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

.addeoSmall {
    #pourQui {
        .containerFond {
            .containerAffichage {
                .droite {
                    .consigne {
                        max-width: 98%;
                    }
                }
            }
        }
    }
}

.addeoMedium.addeoLargeLow {
    #pourQui {
        .containerAffichage {width: 98%;
            max-width: 1260px;
        }
    }
}

html body #app.pro {
    #pourQui h2.sousTitre {
        background: no-repeat center bottom transparent url("~@/assets/img/pro/pourqui/barre.svg");
        max-width: 800px;
        margin:0 auto 1em;
    }

    &.addeoSmall {
        #pourQui h2.sousTitre {
            img {
                max-width: 50%;
            }
            img, span {
                font-size:6vw;
            }
            span {
                line-height:1.2em;
                display: inline-block;
            }
        }
    }
}
    #pourQui {
        width:100%;
        height:auto;
        background: no-repeat center top #F4CE45 url("~@/assets/img/decoupage.png");
        text-align: center;
        color: #4b3c8f;

        .clear {
            clear: both;
            padding:0.5em 0;
        }

        .containerFond {
            width:100%;
            height:auto;
            padding:4em 0 2em;
            min-height: 360px;

/*
            .gauche {
                position:absolute;
                bottom:0;
                left:0;
                width:100%;
                background: no-repeat left bottom transparent none;
                min-height: 600px;
                @media screen and (max-width: 1799px) {
                        max-width: 30%;
                        background-position: right bottom;
                    }
                @media screen and (max-width: 1200px) {
                        display:none;
                    }
            }
*/

            .containerAffichage {
                margin: 2% auto 0 !important;

                @media screen and (min-width: 1800px) {
                    max-width: 1400px !important;
                }

                .droite {
                    float: right;
                }
                .containerConsigne {
                    margin:0 auto;
                    max-width: 920px;
                    text-align: center;
                    @media screen and (max-width: 1799px) {
                        max-width: 800px;
                    }
                    @media screen and (max-width: 1200px) {
                        float:none;
                        margin:0 auto;
                        width:90%;
                    }
                    p {
                        font-size: 1.4em;
                        margin:0 0 0.8em 0;
                        padding-left: 2em;
                    }
                    .fa {
                        font-size:2em ;
                        margin:0 0 0 0.6em;
                        vertical-align: top;
                        /*margin-left:-1.8em;
                        float:left;*/
                    }
                    .consigne {
                        text-align: center;
                        display: inline-block;
                        margin:1em auto;
                        &.avecBloc {
                            margin-bottom:0;
                        }
                        font-size:1.2em ;
                        font-weight: 600;
                        a {
                            .important {
                                font-weight: bold;
                                text-decoration: underline !important;
                                color: #4b3c8f;

                                &:hover {
                                    opacity:0.8;
                                }
                            }

                        }
                        i.lien {
                            color: #356A73;
                            font-size: 3em;
                            margin:0;

                            &:hover {
                                color:white;
                            }
                        }
                    }
                }
            }
        }
        a.bouton {
            display:inline-block;
            border: 1px solid #356A73;
            background-color: #356A73;
            color: rgba(255,255,255,0.8);
            padding: 0.6em 0.8em;
            font-size: 0.75em;
            font-weight: 500;
            margin-top:0.6em;

            &:hover {
                /*opacity: 0.5;*/
                background-color: white;
                color: #356A73;
            }
            span {
                vertical-align: middle;
                height: 2em;
                line-height: 2em;
            }
        }
        .conteneurConnexion {
            /*max-height: 250px;*/
            .conteneurBlocConnexion {
                /*max-height: 250px;*/
            }
            border-radius: 1em;
            max-width: 405px;
            padding:0 2em;
            margin:0 auto;
            background-color: white;
            /*background-color: #E7EAEC;*/
            h3 {
                width:100%;
                text-align: center;
                /*
                top: 20px;
                position:absolute;
                */
            }
        }
        /* transition */
        .fadeHeight-enter-active,
        .fadeHeight-leave-active
        {
          max-height: 378px;
          transition: all 0.4s;
        }

        .fadeHeight-enter,
        .fadeHeight-leave-to
        {
          opacity: 0;
          max-height: 0px;
        }

    }




</style>
