<template>
    <div id="quois">
        <div class="containerFond">
            <div class="containerAffichage">
                <h2 class="sousTitre">
                    La boîte à outils
                        <img  src="../../assets/img/pro/header/logoOrientest.svg" alt="OrientEst" class="centrer marqueOrientest" />
                </h2>
                <p class="teaser">
                    La boîte à outils Orient’Est recense les actions, les événements et les ressources mobilisables, en Région Grand Est, dans le cadre de l’accompagnement des élèves, étudiants, apprentis tout au long de leur parcours d’orientation.
                </p>

                <div class="plusADroite containerConsigne">
                    <div class="consigne">
                        <a class="bouton lien"  @click.stop="blocPlus=!blocPlus">
                            <span>En savoir plus</span>
                            <i v-if="!blocPlus" class="fa fa-solid fa-caret-down"></i>
                            <i v-if="blocPlus" class="fa fa-solid fa-caret-up"></i>
                        </a>
                    </div>
                </div>
                <div class="clear" />

<transition name="fadeHeight1">
                <div class="blocPlus" v-if="blocPlus">
                    <h3>Bloc "Plus"</h3>
                </div>
</transition>
                <div class="clear" />
            </div>
        </div>
    </div>
</template>

<script>
    import Quoi from '@/components/pro/quoi/Quoi.vue'
export default {
    name: 'Quois',
    components: {
        'item-quoi': Quoi,
        },
    props: {
    },
    data() {
        return {
            blocPlus:false,
            myTimeout:null,
            myTimeout2:null,
            itemTmp : "",
            itemCourant : "",
            images : {"visuel01":"img/pro/quois/image01.jpg", "formats02":"img/pro/quois/image02.jpg", "secteurs03":"img/pro/quois/image03.jpg"},
            imagesS : {"visuel01":"img/pro/quois/image01.jpg", "formats02":"img/pro/quois/image02.jpg", "secteurs03":"img/pro/quois/image03.jpg"},
            items : {"visuel01":false,
                "formats02":false,
                "secteurs03":false,},
            itemsTxt : {"visuel01":"Visuels",
                "formats02":"Formats",
                "secteurs03":"Secteurs"},
            itemsCont : {"visuel01":"<p>Visuels</p>",
                "formats02":"<p>Formats</p>",
                "secteurs03":"<p>Secteurs</p>"},
            tmp:true
        }
    },

    created() {
            this.itemTmp = "";
    },

    computed: {
        voirContenu2() {
            return((this.itemCourant != '' || this.itemTmp != '') && ((this.$parent.$parent.addeoLarge) || this.addeoMedium));
        },
        voirContenu3() {
            return((this.itemCourant != '' || this.itemTmp != '') && ((this.$parent.$parent.addeoLarge) || this.addeoMedium));
        },
        lesDatas() {
            var datas = new Object();
            datas.images= this.images;
            datas.imagesS = this.imagesS;
            datas.items= this.items;
            datas.itemsTxt= this.itemsTxt;
            datas.itemsCont= this.itemsCont;
            return(datas);
        },
        addeoSmall() {
            return(this.$parent.$parent.addeoSmall);
        },
        addeoMedium() {
            return(this.$parent.$parent.addeoMedium);
        },
        addeoLarge() {
            return(this.$parent.$parent.addeoLarge);
            //return(this.$parent.$parent.addeoLarge && !this.$parent.$parent.addeoXLarge);
        },
        /*
        addeoXLarge() {
            return(this.$parent.$parent.addeoLarge && this.$parent.$parent.addeoXLarge);
        }
        */
    },
    methods: {
        isInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        isInViewport2(element1, element2) {
            const rect = element1.getBoundingClientRect();
            const rect2 = element2.getBoundingClientRect();
            var h2 = rect2.bottom - rect2.top;
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.top+h2 <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        reinit(item) {

            item !== "visuel01" ? this.items["visuel01"]=false : null
            item !== "formats02" ? this.items["formats02"]=false : null
            item !== "secteurs03" ? this.items["secteurs03"]=false : null

            this.items[item]=!this.items[item];

            this.itemTmp = item;
            if (this.itemCourant == "") {
                if (this.addeoSmall) {
                    this.myTimeout2 = setTimeout(this.sePlacerSurItemS, 250);
                }
                else {
                    this.changerItemCourant();
                }
            }
            else {
                this.itemCourant = "";

                if (this.addeoSmall) {
                    this.myTimeout2 = setTimeout(this.sePlacerSurItemS, 250);
                }
                else {
                    this.myTimeout = setTimeout(this.changerItemCourant, 250);
                }
            }

            // en dernier car on a pu refermer au dessus ...
            if (item !== "") {
                if (this.addeoLarge || this.addeoMedium) {
                    this.myTimeout2 = setTimeout(this.sePlacerSurItemX, 50);
                }
            }

        },
        changerItemCourant() {
            if (this.myTimeout) {
                clearTimeout(this.myTimeout);
                }
            if (this.items[this.itemTmp]) {
                this.itemCourant = this.itemTmp;
            }
            else {
                this.itemCourant = "";
                this.itemTmp = "";
            }
        },
        sePlacerSurItemS() {
            if (this.myTimeout2) {
                clearTimeout(this.myTimeout2);
                }
            var element_to_scroll_to = document.getElementById(this.itemTmp);
            var element_to_validate1 = document.getElementById(this.itemTmp+'1');
            var element_to_validate2 = document.getElementById(this.itemTmp+'2');
            if (!this.isInViewport2(element_to_validate1, element_to_validate2)) {
                element_to_scroll_to.scrollIntoView({alignToTop: true, behavior: "smooth"});
            }

            this.myTimeout = setTimeout(this.changerItemCourant, 500);
        },
        sePlacerSurItemX() {
            if (this.myTimeout2) {
                clearTimeout(this.myTimeout2);
                }

            var element_to_validate1 = document.getElementById('contenu2');
            var element_to_validate2 = document.getElementById('contenu3');
            var element_to_scroll_to = document.getElementById(this.itemTmp);

            if (!this.isInViewport2(element_to_validate1, element_to_validate2)) {
                if ((this.itemTmp == "visuel01") || (this.itemTmp == "formats02") || (this.itemTmp == "secteurs03")) {
                    element_to_scroll_to.scrollIntoView({alignToTop: true, behavior: "smooth"});
                }
                else {
                    element_to_scroll_to.scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

    .addeoSmall {
        #quois {
/*
            #items {

                div.html {
                    width: 90%;
                    max-width: 980px;
                    padding: 0 0 0 10%;
                    text-align: left;

                    &.pasVisible {
                        margin:1em auto 2em;
                        padding-top: 2em;
                        padding-bottom: 2em;
                        font-size: 1.2em;
                        line-height: 1.2;
                        position: absolute;
                        visibility: hidden;
                    }

                    p {
                        margin:0 0 0.8em 0;
                        padding-left: 2em;
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                    span.retourLigne {
                        display: block !important;
                        position: relative;
                        padding: 0;
                        margin: 0;
                        width: 0;
                        height: 0;
                    }
                    .fa {
                        font-size:1.2em ;
                        color:#F3C935;
                        margin-left:-1.8em;
                        float:left;
                    }
                }

                .contenu div.html, div.html {
                    margin-left:5%;
                    margin-right:5%;
                    width:90%;
                    padding-left:0;
                }

                .item {
                    .fond {
                        &.cliquable {
                            display: block !important;
                        }
                    }
                }
            }
*/

        }
    }

    .addeoMedium {
        #quois {
/*
            #items {
                .item {
                    max-width:310px;
                    width:32%;

                    .legende span {
                        font-size:1.8vw ;
                        @media screen and (min-width: 900px) {
                            font-size:1em ;
                        }
                    }

                }

                .contenu > div {
                    font-size:0.8em ;
                    line-height: 1.4;
                    &.barre {
                    }

                    @media screen and (min-width: 800px) {
                        &.barre {
                            min-height: 240px;
                        }
                    }

                    span.retourLigne {
                        display: inline !important;
                        position: relative;
                        padding: 0;
                    }
                }
            }
*/
        }

    }

    .addeoLarge, .addeoSmall {
        #quois {
  /*
            #items {
                .contenu > div {
                    line-height: 1.2;
                }
            }
*/
        }
    }
    .addeoLarge {
        #quois {
/*
            #items {
                .item {
                    width:30%;
                }
            }
*/
        }
    }

    .addeoLarge, .addeoMedium {
        #quois {
            width:100%;
            height:auto;
            background-color: transparent;
/*
            #items {
                .item {
                    &.haut {
                        .legende {
                            top:8%;
                        }
                    }
                }
            }
*/
        }
    }

    #quois {
        width:100%;
        height:auto;
        background-color: transparent;


        .clear {
            clear: both;
            padding:0.5em 0;
        }

        .containerFond {
            width:100%;
            height:auto;
            padding:4em 0 2em;
            min-height: 450px;

            .containerAffichage {
                height:auto;
                margin: 2% auto 0 !important;

                .containerConsigne {
                    margin:0 auto;
                    max-width: 920px;
                    text-align: center;
                    @media screen and (max-width: 1799px) {
                        max-width: 800px;
                    }
                    @media screen and (max-width: 1200px) {
                        float:none;
                        margin:0 auto;
                        width:90%;
                    }
                    p {
                        font-size: 1.4em;
                        margin:0 0 0.8em 0;
                        padding-left: 2em;
                    }
                    .fa {
                        font-size:2em ;
                        margin:0 0 0 0.6em;
                        vertical-align: top;
                        /*margin-left:-1.8em;
                        float:left;*/
                    }
                    .consigne {
                        /*float: right;
                        text-align: right;
                        */
                        display: inline-block;
                        margin:1em auto;
                        /*max-width: 75%;*/
                        font-size:1.2em ;
                        /*font-style: italic; 2021/07*/
                        font-weight: 600;
                        a {
                            .important {
                                font-weight: bold;
                                text-decoration: underline !important;
                                color: #4b3c8f;

                                &:hover {
                                    opacity:0.8;
                                }
                            }
                        }
                    }
                }


                a.bouton {
                    display:inline-block;
                    border: 1px solid #356A73;
                    background-color: #356A73;
                    color: rgba(255,255,255,0.8);
                    padding: 0.6em 0.8em;
                    font-size: 0.75em;
                    font-weight: 500;
                    margin-top:0.6em;

                    &:hover {
                        /*opacity: 0.5;*/
                        background-color: white;
                        color: #356A73;
                    }
                    span {
                        vertical-align: middle;
                        height: 2em;
                        line-height: 2em;
                    }
                }
            }
        }

        .blocPlus {
            min-height: 250px;
            max-width: 800px;
            padding:0 2em;
            margin:0 auto;
            background-color: rgba(200,200,200,0.2);
            text-align: center;
            h3 {
                width:80%;
                margin:0 auto;
                /*
                top: 20px;
                position:absolute;
                */
                padding-top: 1em;
            }
        }

        /* transition */
        .fadeHeight1-enter-active,
        .fadeHeight1-leave-active {
          transition: all 0.4s;
          min-height: 250px;
        }
        .fadeHeight1-enter,
        .fadeHeight1-leave-to
        {
          opacity: 0;
          min-height: 0px;
        }

/*
        #items {

            a.lienQuoi {
                color:#006EA9;
                text-decoration:underline;
                &:hover {
                    color:#4B3C8F;
                }
            }
            .item {
                display:inline-block;
                width:100%;
                max-width:386px;
                cursor:pointer;
                overflow: hidden;

                img {
                    max-width:100%;
                }

                .fond {
                    position:absolute;
                    top:0;
                    left:0;
                    &.cliquable {
                        top:50%;
                        left:40px;
                        transform: translateY(-50%);
                        font-size:30px;
                        z-index: 105;
                        display: none !important;
                        opacity:0.1;
                        color:#4B3C8F;
                        color:white;
                        margin:0;
                        padding:0;
                        text-align: left;
                        &.droite {
                            left:unset;
                            right:40px;
                            text-align: right;
                        }
                    }
                }

                .legende {
                    position:absolute;
                    width:100%;
                    top:70%;
                    left:0;
                    right:0;
                    height:22%;
                    background-color: #356974;
                    color:white;
                    vertical-align: middle;
                    font-weight: 600;

                    span, div {
                        padding: 0 4em;
                        display: inline-block;
                        position: absolute;
                        text-align: center;
                        left: 0px;
                        max-width: 100%;
                        width: 80%;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);

                            span.retourLigne {
                                display: block !important;
                                position: relative;
                                padding: 0;
                                margin: 0;
                                width: 0;
                                height: 0;
                            }
                        }

                }
                .legende.titre {

                    background-color: #F5CA01 !important;
                    span {
                        color: #356974;

                    }
                    .fond {
                        &.cliquable {
                            color:#356974;
                        }
                    }

                }

                .survol {
                    display:none;
                }
                &:hover {
                    .fond {
                        display:none;
                    }
                    .survol {
                        display:block;
                    }
                    .legende {
                        background-color: #4B3C8F;
                    }
                }

            }

            .contenu {
                font-size:1.2em ;
                line-height: 1.2;
                width:100%;
                max-width: 100%;
                vertical-align: middle;
                font-weight: normal;
                color:#356974;
                margin: 2em auto;
                background: no-repeat top center transparent url("~@/assets/img/pro/quois/barreJaune.svg");
                padding-top: 2em;

                div.barre {
                    background: no-repeat bottom center transparent url("~@/assets/img/pro/quois/barreJaune.svg");
                    opacity:1;
                    padding-bottom: 2em;
                    > div {
                    }
                }

                div.html {
                    width: 90%;
                    max-width: 980px;
                    padding: 0 0 0 10%;
                    text-align: left;

                    p {
                        margin:0 0 0.8em 0;
                        padding-left: 2em;
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                    span.retourLigne {
                        display: block !important;
                        position: relative;
                        padding: 0;
                        margin: 0;
                        width: 0;
                        height: 0;
                    }
                    .fa {
                        font-size:1.2em ;
                        color:#F3C935;
                        margin-left:-1.8em;
                        float:left;
                    }
                }

                &.contenu3 {
                    padding-top: 10px;
                    .barre {
                        padding-bottom: 10px;
                        min-height: 260px;
                    }
                    .html {
                        position:absolute;
                        top:50%;
                        transform: translateY(-50%);
                        padding-right: 5%;
                        width: 85%;
                    }
                    .pasVisible {
                        margin:2em auto;
                        padding-top: 2em;
                        padding-bottom: 2em;
                        font-size: 1.2em;
                        line-height: 1.2;
                        position: absolute;
                        visibility: hidden;
                    }
                }
            }
            .contenu1 {
                margin-top: 1em;
            }
        }
*/






/*
// Enter and leave animations can use different
// durations and timing functions.
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
// .slide-fade-leave-active below version 2.1.8
        {
  transform: translateY(30%);
  opacity: 0;
}

// Enter and leave animations can use different
// durations and timing functions.
.slideX-fade-enter-active {
  transition: all .3s ease;
}
.slideX-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slideX-fade-enter, .slideX-fade-leave-to
// .slide-fade-leave-active below version 2.1.8
        {
  transform: translateX(40%);
  opacity: 0;
}
*/
    }
</style>
