<template>
    <div id="porteursPro">
        <div class="containerAffichage">
            <h2 class="sousTitre"><span class="commeSousTitre insecable">La Région</span><img src="../../assets/img/header/logoGrandE.svg" alt="Lorfolio" class="centrer" /><span class="commeSousTitre insecable">et ses partenaires</span></h2>

            <p class="teaser">
                La Région Grand Est et les autorités académiques du Grand Est se sont unies pour élaborer la Boîte à outils Orient'Est.
                <br/>
                Cet outil a pour objectif de faciliter les missions d'accompagnement des élèves dans l'élaboration de leur proje d'orientation scolaire et professionnelle.
                <br/>
                La Boîte à Outils Orient'Est présente les actions, les ressources et les événements sur les métiers et les formations en Région Grand Est.

            </p>

            <div id="items" class="separateur">
                    <item-porteur :data="lesDatas" item="GE01" />
                    <item-porteur :data="lesDatas" item="Onisep02" />
                    <item-porteur :data="lesDatas" item="Academie03"  class="dernier"/>
            </div>

            <p class="teaser">
                Elle a été réalisée en <router-link to="/partenaires-pro">partenariat avec le Service Public Régional de l'Orientation</router-link>, et des acteurs économiques (les chambres consulaires, les branches professionnelles, et des associations locales ou nationales).
            </p>
            <div class="separateur">
            </div>
        </div>
    </div>
</template>

<script>
    import Porteur from '@/components/pro/porteurs/Porteur.vue'
export default {
    name: 'Porteurs',
    components: {
        'item-porteur': Porteur,
        },
    props: {
    },
    data() {
        return {
            images : {"GE01":"img/pro/porteurs/logoGrandEst.svg", "Onisep02":"img/pro/porteurs/logoOnisep.png", "Academie03":"img/pro/porteurs/logoAcademie.svg"},
            itemsTitre : {"GE01":"Région Grand Est", "Onisep02":"Onisep", "Academie03":"Région Academique Grand Est"},
            itemsCont : {"GE01":"https://www.grandest.fr/",
                         "Onisep02":"https://www.onisep.fr/",
                         "Academie03":"https://www.ac-nancy-metz.fr/la-region-academique-grand-est-121460"},
            tmp:true
        }
    },

    computed: {
        lesDatas() {
            var datas = new Object();
            datas.images= this.images;
            datas.itemsTitre= this.itemsTitre;
            datas.itemsCont= this.itemsCont;
            return(datas);
        }
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

.addeoSmall {
    #porteursPro {
        #items {
            max-width: 95%;

            #porteur {
                width:100%;
/*
                img {
                    max-height: 80px;
                }
*/
                .contenu {
                        font-size:1.2em;
                    /*
                    font-size:2.4vw;
                    @media screen and (min-width: 800px) {
                        font-size:1.2em;
                    }
                    */
                }
            }
        }
    }
}

.addeoMedium {
    #porteursPro {
        /*padding-top:3em;*/

        #items {
            max-width: 95%;

            #porteur {
                width:50%;

                img {
                    max-height: 80px;
                }

                .contenu {
                    font-size:2.4vw;
                    @media screen and (min-width: 800px) {
                        font-size:1.2em;
                    }
                }
            }
        }
    }
}

#app {
    #porteursPro {
        div.containerAffichage {
            margin-top: 3%;
        }
    }


    #porteursPro h2.sousTitre {
        background: no-repeat center bottom transparent url("~@/assets/img/pro/porteurs/barre.svg");
        .commeSousTitre {
            color:#356A72;
        }

        img, span {
            vertical-align: middle;
            font-size:90%;
        }
        img {
            width: 220px;
            max-width: 30%;
            margin:0 0.4em;
        }
        margin:0 auto 0.6em;
        padding: 0.8em 0 0.6em;
    }
    &.addeoSmall {
        #porteursPro h2.sousTitre {
            img {
                max-width: 50%;
            }
            img, span {
                font-size:6vw;
            }
        }
    }

}
    #porteursPro {
        width:100%;
        height:auto;
        /*background: no-repeat center top #EDF2F5 url("~@/assets/img/decoupage.png");*/
        text-align: center;
        padding:4em 0 2em;

        .dernier {
            .item {
                background-image: none;
            }
        }

        #items {
            padding-top:1em;
            max-width: 90%;
            margin:0 auto;
            text-align: center;
            background: no-repeat bottom center transparent url("~@/assets/img/pro/porteurs/barreJaune.svg");
        }
        .separateur {
            background: no-repeat bottom center transparent url("~@/assets/img/pro/porteurs/barreJaune.svg");
            width:100%;
            min-height: 10px;
        }

        #porteur {
            /*display:inline-block;
            width:33.3%;*/
            display:block;
            width:90%;
            min-width:291px;
            margin: 0 auto;
            vertical-align: top;
        }
        .item {
            display:inline-block;
            width:95%;
            margin:2em auto 0;
            text-align: center;
            /*margin:2em auto 0;
            padding-bottom:2em;*/
            margin:1em auto 0;
            padding-bottom:1em;


            img {
                display:block;
                width:100%;
                /*width:auto;*/
                height: auto;
                max-height: 130px;
                max-width: 580px;
                margin: 0 auto 1em;
                padding:0;
            }



            .contenu {
                text-align: center;
                width:100%;
                font-weight: normal;
                color:#4B3C8F;
                font-weight: 400;
                font-size:1.5vw;

                @media screen and (min-width: 1250px) {
                    font-size:1.2em ;
                }

                span {
                    line-height: 1.4;
                    .important {
                        font-weight: 600;
                    }
                    .retourLigne {
                        display: block !important;
                        position: relative;
                        padding: 0;
                        margin: 0;
                        width: 0;
                        height: 0;
                    }
                }
            }



        }


    }
</style>
